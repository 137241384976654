import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction } from
"@remix-run/cloudflare";
import { json } from "@remix-run/cloudflare";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData } from
"@remix-run/react";
import type { ReactNode } from "react";
import { StrictMode, useEffect } from "react";

import { Error } from "~/components/site/error";
import { ROUTE_DATA, SITE } from "~/lib/constants";
import type { Ctx, Environment } from "~/lib/env";
import { getEnv } from "~/lib/env";
import { getMetaWithUrl } from "~/lib/get-meta";
import { getCanonicalUrl, getUrls } from "~/lib/get-urls";
import { initialize } from "~/lib/growthbook";
import { StructuredData } from "~/lib/structured-data";
import { getThemeSession } from "~/lib/theme.server";
import type { Theme } from "~/lib/theme-provider";
import {
  NonFlashOfWrongThemeEls,
  ThemeProvider,
  useTheme } from
"~/lib/theme-provider";

import styles from "./app.css";

const { desc, path, title } = ROUTE_DATA.home;

type LoaderData = Readonly<{
  env: Environment;
  theme: Theme | null;
}>;

type ChildrenProps = Readonly<{
  children: ReactNode;
}>;

type DocumentProps = ChildrenProps &
Readonly<{data?: LoaderData;theme?: Theme | null;}>;

const growthBook = new GrowthBook(



  /*{
  trackingCallback: ({ key }, { variationId }) => {
  console.log("Experiment", { experimentId: key, variationId: variationId });
  },
  }*/);export const links: LinksFunction = () => [{ rel: "alternative", href: "/feed.xml", type: "application/rss+xml" }, {
  rel: "apple-touch-icon",
  href: "/favicon/apple-touch-icon.png",
  sizes: "180x180"
},
{ rel: "icon", href: "/favicon/favicon.ico", sizes: "any" },
{ rel: "icon", href: "/favicon/favicon.svg", type: "image/svg+xml" },
{ rel: "manifest", href: "/favicon/site.webmanifest" },
{ rel: "mask-icon", href: "/favicon/favicon.svg" },
{ rel: "stylesheet", href: styles },
{
  rel: "stylesheet",
  href: "https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap"
},
{
  rel: "stylesheet",
  href: "https://assets.calendly.com/assets/external/widget.css"
},
{
  rel: "stylesheet",
  href: "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
},
{
  rel: "stylesheet",
  href: "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
}];


export async function loader({ context, request }: LoaderFunctionArgs) {
  const ctx = (context as Ctx);

  return json({
    env: getEnv(ctx),
    theme: (await getThemeSession(ctx, request)).getTheme(),
    urls: getUrls({ path, request })
  });
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  if (data) {
    const imageUrl = `${data.urls.base}${SITE.image.path}`;
    return [
    ...getMetaWithUrl({ desc, title, url: getCanonicalUrl(path) }),
    { property: "og:image", content: imageUrl },
    { property: "og:type", content: "website" },
    { nam: "theme-color", content: "#000" },
    { property: "twitter:alt", content: SITE.image.alt },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:creator", content: SITE.social.twitter },
    { property: "twitter:domain", content: SITE.domain },
    { name: "twitter:image", content: imageUrl },
    { name: "twitter:site", content: SITE.social.twitter }];

  } else return [];
};

export default function App(): JSX.Element {
  return (
    <DocumentWithProviders>
      <Outlet />
      <ScrollRestoration />
    </DocumentWithProviders>);

}

function DocumentWithProviders({ children }: ChildrenProps) {
  const data = useLoaderData<LoaderData>();

  useEffect(() => {
    async function load() {
      await initialize({ env: data.env, isClient: true, growthBook });
    }

    load();
  }, [data.env]);

  return (
    <StrictMode>
      <GrowthBookProvider growthbook={growthBook}>
        <ThemeProvider specifiedTheme={data.theme}>
          <ThemedDocument>{children}</ThemedDocument>
        </ThemeProvider>
      </GrowthBookProvider>
    </StrictMode>);

}

function ThemedDocument({ children }: ChildrenProps) {
  const data = useLoaderData<LoaderData>();
  const [theme] = useTheme();

  return (
    <Document data={data} theme={theme}>
      {children}
    </Document>);

}

function Document({ children, data, theme }: DocumentProps) {
  return (
    <html
      lang="en"
      className={`h-full scroll-smooth antialiased hover:prose-a:no-underline ${
      theme ?? ""
      }`}>

      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <StructuredData />
        <script
          defer
          data-domain={SITE.domain}
          data-api="/data/api"
          src="/data/script">
        </script>
        <script
          src="https://assets.calendly.com/assets/external/widget.js"
          type="text/javascript"
          async
          id="calendly">
        </script>
        <script src="/scripts/intercom.js" type="text/javascript"></script>

        {data && <NonFlashOfWrongThemeEls ssrTheme={Boolean(data?.theme)} />}
      </head>
      <body className="flex h-full flex-col bg-primary">
        {children}
        <Scripts />
        <LiveReload />
      </body>
    </html>);

}

export const ErrorBoundary = () => {
  return (
    <Document>
      <Error statusCode={500} themed={false} />
    </Document>);

};